<template>
  <div>
    <draggable :list="expApplications" handle=".mover">
      <div
        class="white lighten-2 my-6 px-4 d-flex flex-column"
        style="width: 100%; margin: 0 auto"
        v-for="(item, index) in expApplications"
        :key="`${item.name}_${index}`"
      >
        <div
          class="d-inline-flex flex-row align-center justify-space-between"
          style="border-bottom: 1px dotted grey; line-height: 48px"
        >
          <div class="text-h6">
            <v-btn icon class="mover">
              <v-icon>mdi-format-line-spacing</v-icon>
            </v-btn>
            {{ item.name || "门户站点" }}
          </div>
          <div class="text-h6">
            <v-btn class="my-1" color="success" @click="addSonSite(index)"
              >新增子站点</v-btn
            >
            <v-btn
              class="mx-4 my-1"
              color="success"
              @click="editGroupSite(item, index)"
              >编辑</v-btn
            >
            <v-btn
              class="my-1"
              color="success"
              @click="deleteGroupSite(item, index)"
              >删除</v-btn
            >
          </div>
        </div>
        <draggable
          group="B"
          :list="item.links"
          handle=".mover"
          v-if="item.links"
          :move="onMove"
        >
          <div
            class="white"
            v-for="(itm, idx) in item.links"
            :key="`${itm.id}_${idx}`"
          >
            <div
              class="text-caption d-flex flex-row align-center justify-space-between pl-2"
              style="
                    border-bottom: 1px solid #bdbdbd;
                    height: 48px;
                    line-height: 48px;
                  "
            >
              <div class="black--text">
                <v-btn icon class="mover">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>

                {{ itm.name }} &nbsp;&nbsp;&nbsp;&nbsp; ({{ itm.url }})
              </div>
              <div>
                <div class="text-h6">
                  <v-btn icon @click="orderSonSite(itm, index, idx)">
                    <v-icon>mdi-cog-transfer-outline</v-icon>
                  </v-btn>
                  <v-btn icon @click="editSonSite(itm, index, idx)">
                    <v-icon>mdi-tune-vertical</v-icon>
                  </v-btn>
                  <v-btn icon color="red" @click="deleteSonSite(itm)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </draggable>
      </div>
    </draggable>

    <draggable
      v-if="unexpApplications.length > 0"
      id="ungroup"
      :list="unexpApplications"
      handle=".mover"
    >
      <transition-group>
        <div
          class="white lighten-2 my-6 px-4 d-flex flex-column"
          style="width: 100%; margin: 0 auto"
          v-for="(item, index) in unexpApplications"
          :key="`${item.name}_${index}`"
        >
          <div
            class="d-inline-flex flex-row align-center justify-space-between"
            style="border-bottom: 1px dotted grey; line-height: 48px"
          >
            <div class="text-h6">
              {{ item.name || "门户站点" }}
            </div>
          </div>
          <draggable
            group="B"
            :list="item.links"
            handle=".mover"
            v-if="item.links"
          >
            <div
              class="white ungroup-sites "
              v-for="(itm, idx) in item.links"
              :key="`${itm.id}_${idx}`"
            >
              <div
                class="text-caption d-flex flex-row align-center justify-space-between pl-2"
                style="
                    border-bottom: 1px solid #bdbdbd;
                    height: 48px;
                    line-height: 48px;
                  "
              >
                <div class="black--text">
                  <v-btn icon class="mover">
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>

                  {{ itm.name }} &nbsp;&nbsp;&nbsp;&nbsp; ({{ itm.url }})
                </div>
                <v-btn icon @click="editSonSite(itm, index, idx, 'ungroup')">
                  <v-icon>mdi-tune-vertical</v-icon>
                </v-btn>
              </div>
            </div>
          </draggable>
        </div>
      </transition-group>
    </draggable>

    <Component
      :is="sheet_editor"
      :type="type"
      :isUngroup="isUngroup"
      :all_sites="sites"
      :expApplications="expApplications"
      :groupSiteData="groupSiteData"
      :curGroupSiteIndex="curGroupSiteIndex"
      :sonSiteData="sonSiteData"
      :curSonSiteIndex="curSonSiteIndex"
      @addGroupEvent="addGroupEvent($event)"
      @editGroupEvent="editGroupEvent($event)"
      @addSonEvent="addSonEvent($event)"
      @editSonEvent="editSonEvent($event)"
      @editUngroupSonEvent="editUngroupSonEvent($event)"
      @orderSonEvent="orderSonEvent($event)"
    />

    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import ConfirmBox from "@/components/ConfirmBox";
import GroupSiteEditor from "@/views/application/compEditor/GroupSite";
import SonSiteEditor from "@/views/application/compEditor/SonSite";
import OrderSonSiteEditor from "@/views/application/compEditor/OrderSonSite";

const EDITORS = {
  GROUPSITE: GroupSiteEditor,
  SONSITE: SonSiteEditor,
  ORDERSONSITE: OrderSonSiteEditor
};

export default {
  name: "SiteManage",
  props: ["allDatas"],
  data() {
    return {
      sheet_editor: null,
      drawer: false,
      type: null,
      sites: [],
      expApplications: [],
      unexpApplications: [],
      groupSiteData: {},
      curGroupSiteIndex: "",
      sonSiteData: {},
      curSonSiteIndex: "",
      isUngroup: ""
    };
  },
  created() {
    this.fetchApplications();
    bus.$off("SITE").$on("SITE", () => {
      this.watchWebexpGroups();
    });
  },
  methods: {
    onMove(e) {
      if (
        !e.related._prevClass ||
        (e.related._prevClass && e.related._prevClass.includes("ungroup-sites"))
      ) {
        return false;
      } else {
        return true;
      }
    },
    fetchApplications() {
      this.$http
        .get(`api/application/${this.$route.params.id}/describe_webexp_sites`)
        .delegateTo(api_request)
        .then(data => {
          this.sites = data.sites;
          this.expApplications = data.webexp_groups;

          let ungroups = [
            {
              name: "未分组",
              links: data.webexp_ungroups
            }
          ];

          this.unexpApplications =
            data.webexp_ungroups.length > 0 ? ungroups : [];

          let expApplications = [...this.expApplications];

          localStorage.setItem(
            "webexp_groups",
            JSON.stringify(expApplications)
          );

          localStorage.setItem(
            "webexp_ungroups",
            JSON.stringify(this.unexpApplications)
          );
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    // 保存排序
    saveSort() {
      let payload = {
        webexp_groups: this.expApplications.map(item => {
          let obj = {};
          obj.name = item.name;
          obj.links = item.links;

          return obj;
        }),
        webexp_sites: this.unexpApplications[0].links.map(item => {
          let obj = {};
          obj.site_id = item.site_id;
          obj.disable = item.disable;

          return obj;
        })
      };
      this.$http
        .patch(`api/application/${this.$route.params.id}/attributes`, payload)
        .delegateTo(api_request)
        .then(() => {
          return "保存成功";
        })
        .delegateTo(this.$snackbar.delegate);
    },
    // 新增组站点
    addGroupSite() {
      this.type = "create";
      this.sheet_editor = EDITORS["GROUPSITE"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    // 编辑组站点
    editGroupSite(item, index) {
      this.groupSiteData = item;
      this.curGroupSiteIndex = index;
      this.type = "edit";
      this.sheet_editor = EDITORS["GROUPSITE"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    // 删除组站点
    deleteGroupSite(item, index) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除:第${index + 1}行组站点${item.name}？`,
          callback: () => {
            let webexp_groups = JSON.parse(
              localStorage.getItem("webexp_groups")
            );
            webexp_groups.splice(index, 1);
            this.expApplications = webexp_groups;
            localStorage.setItem(
              "webexp_groups",
              JSON.stringify(this.expApplications)
            );
            return new Promise(resolve => resolve());
          }
        })
        .catch(() => {
          return;
        });
    },
    // 新增子站点
    addSonSite(index) {
      this.curGroupSiteIndex = index;
      this.type = "create";
      this.sheet_editor = EDITORS["SONSITE"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    // 排序子站点
    orderSonSite(itm, index, idx) {
      this.sonSiteData = itm;
      this.curGroupSiteIndex = index;
      this.curSonSiteIndex = idx;
      this.type = "edit";
      this.sheet_editor = EDITORS["ORDERSONSITE"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    // 编辑子站点
    editSonSite(itm, index, idx, ungroup = null) {
      this.sonSiteData = itm;
      this.curGroupSiteIndex = index;
      this.curSonSiteIndex = idx;
      this.type = "edit";
      this.isUngroup = ungroup;
      this.sheet_editor = EDITORS["SONSITE"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    // 删除子站点
    deleteSonSite(itm) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除:子站点${itm.name}？`,
          callback: () => {
            let webexp_groups = JSON.parse(
              localStorage.getItem("webexp_groups")
            );
            let data = this.deleteSonSiteEvent(webexp_groups, itm);
            this.expApplications = data;
            localStorage.setItem(
              "webexp_groups",
              JSON.stringify(this.expApplications)
            );
            return new Promise(resolve => resolve());
          }
        })
        .catch(() => {
          return;
        });
    },
    addGroupEvent(data) {
      let webexp_groups = JSON.parse(localStorage.getItem("webexp_groups"));
      this.expApplications = [data, ...webexp_groups];
      localStorage.setItem(
        "webexp_groups",
        JSON.stringify(this.expApplications)
      );
    },
    editGroupEvent(groupdata) {
      let {
        editGroup: {
          index,
          data: { name }
        }
      } = groupdata;

      let webexp_groups = JSON.parse(localStorage.getItem("webexp_groups"));
      webexp_groups[index].name = name;
      this.expApplications = webexp_groups;

      localStorage.setItem(
        "webexp_groups",
        JSON.stringify(this.expApplications)
      );
    },
    addSonEvent(sondata) {
      let { index, data } = sondata;
      let webexp_groups = JSON.parse(localStorage.getItem("webexp_groups"));
      let newSites = [data, ...webexp_groups[index].links];
      webexp_groups[index].links = newSites;
      this.expApplications = webexp_groups;
      localStorage.setItem(
        "webexp_groups",
        JSON.stringify(this.expApplications)
      );
    },
    editSonEvent(sondata) {
      let { index, idx, data } = sondata;
      let webexp_groups = JSON.parse(localStorage.getItem("webexp_groups"));
      webexp_groups[index].links.splice(idx, 1, data);
      this.expApplications = webexp_groups;
      localStorage.setItem(
        "webexp_groups",
        JSON.stringify(this.expApplications)
      );
    },
    editUngroupSonEvent(sondata) {
      let { index, idx, data } = sondata;
      let webexp_ungroups = JSON.parse(localStorage.getItem("webexp_ungroups"));
      webexp_ungroups[index].links.splice(idx, 1, data);
      this.unexpApplications = webexp_ungroups;
      localStorage.setItem(
        "webexp_ungroups",
        JSON.stringify(this.unexpApplications)
      );
    },
    deleteSonSiteEvent(list, obj) {
      for (var item of list) {
        item.links = item.links.filter(itm => {
          if (itm.name === obj.name && itm.id === obj.id) {
            return false;
          }
          return true;
        });
      }
      return list;
    },
    watchWebexpGroups() {
      let webexp_groups = localStorage.getItem("webexp_groups");
      this.expApplications = JSON.parse(webexp_groups);
    },
    // 排序
    orderSonEvent(orderData) {
      let { preIndex, preIdx, index, idx, data } = orderData;
      let webexp_groups = JSON.parse(localStorage.getItem("webexp_groups"));
      webexp_groups[preIndex].links.splice(preIdx, 1);
      webexp_groups[index].links.splice(idx - 1, 0, data);
      this.expApplications = webexp_groups;
      localStorage.setItem(
        "webexp_groups",
        JSON.stringify(this.expApplications)
      );
    }
  },
  watch: {
    expApplications: {
      handler() {
        // do
        localStorage.setItem(
          "webexp_groups",
          JSON.stringify(this.expApplications)
        );
      },
      deep: true
    }
  },
  components: {
    draggable,
    ConfirmBox,
    GroupSiteEditor,
    SonSiteEditor,
    OrderSonSiteEditor
  }
};
</script>
